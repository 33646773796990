import PropTypes from 'prop-types'
import React from 'react'
import JSONData from '../content/mycontent.json'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo backdrop-blur">
      <img
        src={JSONData.MainImage}
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          display: 'block',
          borderRadius: '50%',
        }}
      ></img>
    </div>
    <div className="content backdrop-blur">
      <div className="inner backdrop-blur">
        <h1
          style={{
            fontFamily: 'Open Sans, sans-serif',
            fontWeight: '300',
          }}
        >
          {JSONData.Name}
        </h1>
        ____
        <p>
          {JSONData.Description.map(item => {
            return (
              <div
                style={{
                  fontFamily: 'Fira Mono, monospace',
                  fontWeight: '100',
                  fontSize: 'small',
                  letterSpacing: '1px',
                }}
              >
                {item}
              </div>
            )
          })}
        </p>
      </div>
    </div>
    {true &&
    <nav
      className="backdrop-blur"
      style={{
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
      }}
    >
      <ul className="icons">
        <li>
          <a
            target="_blank"
            href={JSONData.emailid}
            className="icon fa-envelope"
          >
            <span className="label">Email</span>
          </a>

        </li>
        <li>
          <a
            target="_blank"
            href={JSONData.telegram}
            className="icon fa-telegram"
          >
            <span className="label">Telegram</span>
          </a>

        </li>
        <li>
          <a
            target="_blank"
            href={JSONData.linkedin}
            className="icon fa-linkedin"
          >
            <span className="label">Linkedin</span>
          </a>

        </li>
        <li>
          <a
            target="_blank"
            href={JSONData.github}
            className="icon fa-github"
          >
            <span className="label">GitHub</span>
          </a>
        </li>
        <li>
          <a
            target="_blank"
            href={JSONData.twitter}
            className="icon fa-twitter"
          >
            <span className="label">Twitter</span>
          </a>
        </li>
      </ul>
    </nav>
    }
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
